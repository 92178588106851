<template>
  <section class="hero is-primary is-fullheight login-principal-container">
    <!-- Hero head: will stick at the top -->
    <div class="hero-head">
      <nav class="navbar">
        <div class="container">
          <div class="navbar-brand">
            <a class="navbar-item" href="https://www.coconutcontrol.com/">
              <img src="@/assets/img/logo-final.png" />
            </a>
            <span class="navbar-burger burger" data-target="navbarMenuHeroA">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </div>
          <div id="navbarMenuHeroA" class="navbar-menu">
            <div class="navbar-end"></div>
          </div>
        </div>
      </nav>
    </div>

    <!-- Hero content: will be in the middle -->
    <div class="hero-body">
      <div class="container has-text-centered">
        <div class="columns is-centered absolut-center">
          <div class="column is-half login-form-container">
            <p class="title" data-testid="login-title">¡Pago no realizado!</p>
          </div>
          <div class="column is-half payment-message-container">
            El pago no fue realizado, no te preocupes, puedes continuar con el
            proceso una vez inicies sesión.
          </div>
          <div class="column is-half payment-button">
            <b-button class="payment-button" @click="openLogin()"
              >Ir a inicio de sesión</b-button
            >
          </div>
        </div>
      </div>
    </div>

    <!-- Hero footer: will stick at the bottom -->
    <div class="hero-foot">
      <nav class="tabs">
        <div class="container">
          <ul>
            <li class="is-active"><a></a></li>
            <li><a>Punto de venta</a></li>
            <li><a>Control de inventario</a></li>
            <li><a>Venta de productos</a></li>
            <li><a>Administración de sucursales</a></li>
            <li><a>Control de pedidos</a></li>
          </ul>
        </div>
      </nav>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
export default {
  name: "PaymentCancelled",
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    openLogin() {
      this.$store.dispatch("LOGOUT_DATABASE");
      this.$store.dispatch("LOGOUT");
      this.$router.push({ path: "/Login" }).catch(() => {});
    },
  },
};
</script>

<style scoped>
@import "../style/Login.css";
@import "../style/Global.css";
</style>
